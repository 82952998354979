import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Layout, Carousel } from 'components';
import { ArtCollections } from 'containers';

const ArtCollectionsPage = () => {
  const data = useStaticQuery(query);
  const imgList = data.allStrapiArtCollections.nodes[0].carousel_img;
  const pageDescription = data.strapiArtCollections.page_description;
  const arts = data.allStrapiArts;

  return (
    <Layout>
      <Carousel
        imgList={imgList}
        pageTitle={'전시'}
        pageDescription={pageDescription}
      />
      <ArtCollections title={'전시 작품'} arts={arts} />
    </Layout>
  );
};

const query = graphql`
  query {
    allStrapiArtCollections {
      nodes {
        carousel_img {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
    allStrapiArts {
      nodes {
        artist
        date
        description
        medium
        title
        size
        art {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        title
      }
    }
    strapiArtCollections {
      page_description
    }
  }
`;

export default ArtCollectionsPage;
